import React from 'react'
import { Link, graphql } from 'gatsby'
import { css } from '@emotion/core'
import Button from '../components/Button'

const ListLink = props => (
    <li
        css={css`
            a {
                color: inherit;
                text-decoration: none;
                font-size: 1.125em;
                font-family: var(--font-serif);
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        `}
    >
        {
            ! props.to.match(/^http/)
            ?
            <Link
                to={props.to}
                activeStyle={{ textDecoration: 'underline' }}
            >{props.children}</Link>
            :
            <a href={props.to} target="_blank" rel="nooppener noreferrer">{props.children}</a>
        }
    </li>
)

export default () => (
    <nav
        css={css`
            border-top: 1px dashed var(--color-bright);
            margin-top: 2.5em;
            padding: 2em 1.5em 2em;
            background-color: var(--color-bg);
            @media ( min-width: 48em ) {
                margin-top: 5em;
            }
        `}
    >
        <h3
            css={css`
                position: absolute;
                top: 0;
                transform: translate3d(0,-50%,0);
                padding: 0.5em;
                background-image: linear-gradient(to bottom, #fff, #fff 50%, var(--color-bg) 50%, var(--color-bg) );
                margin: 0 0 0 -0.5em;
                font-size: 0.875em;
                font-family: var(--font-wide);
                line-height: 1;
                text-transform: uppercase;
                letter-spacing: 0.05em;
                color: var(--color-bright);
                @media ( max-width: 48em ) {
                    left: 50%;
                    transform: translate3d(-50%,-50%,0);
                    white-space: nowrap;
                    margin-left: 0;
                }
            `}
        >Explore Further</h3>
        <ul
            css={css`
                list-style: none;
                margin: 0;
                padding: 0;
                @media ( max-width: 48em ) {
                    li:not(:first-of-type) {
                        margin-top: 1em;
                    }
                    li span {
                        display: block;
                        text-align: center;
                    }
                }
                @media ( min-width: 48em ) {
                    display: flex;
                    justify-content: space-between;
                }
            `}
        >
            <ListLink to="/approach"><Button>Our Approach</Button></ListLink>
            <ListLink to="/a-day-at-acton"><Button>A Day at Acton</Button></ListLink>
            <ListLink to="/projects"><Button>Learner Projects</Button></ListLink>
            <ListLink to="/campus"><Button>Around the Campus</Button></ListLink>
        </ul>
    </nav>
)
