import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { css } from '@emotion/core'
import Layout from '../components/layout'
import PageHeader from '../components/PageHeader'
import PageWrapper from '../components/PageWrapper'
import Img from 'gatsby-image'
import ExploreFurther from '../components/ExploreFurther'

export const query = graphql`
    query {
        imageHero: file(relativePath: { eq: "images/acton-hero.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                fluid {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        site {
            siteMetadata {
                title
            }
        }
    }
`

export default ({ data }) => (
    <Layout>
        <Helmet>
            <title>Thank You | {data.site.siteMetadata.title}</title>
        </Helmet>
        <main>
            <article>
                <PageHeader title="Thank You" excerpt="<p>Thanks for your interest. We&rsquo;ll be in touch shortly.</p>" />
                { data.imageHero &&
                    <figure
                        css={css`
                            position: relative;
                            z-index: 1;
                            &::before {
                                content: '';
                                position: absolute;
                                display: block;
                                top: 50%;
                                bottom: 0;
                                width: 100vw;
                                left: 50%;
                                margin-left: -50vw;
                                background-color: #fff;
                            }
                            @media( max-width: 48em ) {
                                position: relative;
                                margin: -2em -7em -1em;
                            }
                            @media ( min-width: 48em ) {
                                position: relative;
                                max-width: 52em;
                                margin: -2em auto -1em;
                            }
                        `}
                    >
                        <Img fluid={data.imageHero.childImageSharp.fluid} alt="" />
                    </figure>
                }
                <PageWrapper>
                    <ExploreFurther />
                </PageWrapper>
            </article>
        </main>
    </Layout>
)
